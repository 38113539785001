import * as THREE from 'three';
import CameraControls from 'camera-controls';

import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';
import { getLogoUrl } from './api';

CameraControls.install( { THREE: THREE } );
THREE.Cache.enabled = true;

const getVectorForPosition = (position, index) => {
  return new THREE.Vector3(position.getX(index), position.getY(index), position.getZ(index));
};

export const init = (element) => {
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
  const clock = new THREE.Clock();

  const renderer = new THREE.WebGLRenderer();

  element.innerHtml = "";
  element.appendChild(renderer.domElement);
  renderer.setSize(window.innerWidth, window.innerHeight);

  // const controls = new TrackballControls(camera, renderer.domElement);
  // const controls = new OrbitControls( camera, renderer.domElement );
  const controls = new CameraControls( camera, renderer.domElement );

  controls.autoRotateSpeed = 0.2
  controls.distance = 3
  controls.maxDistance = 4
  controls.minDistance = 2
  controls.smoothTime = 0.25

  const pointer = new THREE.Vector2();

  document.addEventListener('pointermove', (event) => {
    pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    pointer.y = - (event.clientY / window.innerHeight) * 2 + 1;
  });

  window.addEventListener('resize', () => {
    const tanFOV = Math.tan(((Math.PI / 180) * camera.fov / 2));
    const windowHeight = window.innerHeight;

    camera.aspect = window.innerWidth / window.innerHeight;

    // adjust the FOV
    camera.fov = (360 / Math.PI) * Math.atan(tanFOV * (window.innerHeight / windowHeight));

    let paddingReference = 0;
    console.log(document.getElementById('infoPanel'), document.getElementById('selectorPanel'))
    if (true) {
      paddingReference = document.getElementById('infoPanel').clientHeight;
      if (paddingReference === 0) {
        paddingReference = document.getElementById('selectorPanel').clientHeight + (document.getElementById('selectionPanel') ? document.getElementById('selectionPanel').clientHeight + 8 : 0);
      }
    }
    const width = window.innerWidth;
    const height = window.innerHeight;
    const paddingLeft = 20;
    const paddingRight = 20;
    const paddingTop = 20;
    const paddingBottom = paddingReference + 20;
    const fullWidth = width - paddingLeft + paddingRight;
    const fullHeight = height - paddingTop + paddingBottom;
    const widthOffset = - paddingLeft + paddingRight;
    const heightOffset = - paddingTop + paddingBottom;
    const viewWidth = width;
    const viewHeight = height;
    camera.setViewOffset( fullWidth, fullHeight, widthOffset, heightOffset, viewWidth, viewHeight );
    camera.updateProjectionMatrix();
    
    camera.lookAt(scene.position);
    
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.render(scene, camera);

    // @todo: adjust center

  }, false);

  scene.fog = new THREE.FogExp2( 0x000000, 0.15 );

  window.dispatchEvent( new Event('resize') );

  return {
    scene,
    camera,
    renderer,
    controls,
    pointer,
    clock,
  };
};

export const createPlanet = async ({ renderer }, name) => {

  const addRings = () => {
    return new Promise(resolve => {
      loader.load('/rings.obj', function (rings) {
        let ringsObj = null;
        rings.traverse(function (child) {
          if (child.isMesh) {
            ringsObj = child;
          }
        });
        new THREE.TextureLoader().load('/rings.png', (texture) => {
          texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
          texture.anisotropy = renderer.capabilities.getMaxAnisotropy();
          texture.colorSpace = THREE.SRGBColorSpace;
  
          const material = new THREE.MeshBasicMaterial({ map: texture, wireframe: false, flatShading: false, opacity: 0.4, transparent: true });
  
          ringsObj.material = material;
          
          resolve(ringsObj);
        });
      });
    })
  };

  const loader = new OBJLoader();

  return new Promise(resolve => {
    loader.load('/planet.obj', function (gltf) {
      let gltfObj = null;
      gltf.traverse(function (child) {
        if (child.isMesh) {
          gltfObj = child;
        }
      });

      loader.load('/planet-uv2.obj', function (gltfUv) {
        let gltfUvObj = null;
        gltfUv.traverse(function (child) {
          if (child.isMesh) {
            gltfUvObj = child;
          }
        });

        const planet = gltfObj;
        const uv = gltfUvObj.geometry.getAttribute('uv');

        new THREE.TextureLoader().load('/' + name + '.jpeg', async (texture) => {
          texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
          texture.anisotropy = renderer.capabilities.getMaxAnisotropy();
          texture.colorSpace = THREE.SRGBColorSpace;

          const material = new THREE.MeshBasicMaterial({ map: texture, wireframe: false, flatShading: false });

          planet.material = material;
          
          resolve({
            planet,
            uv,
            rings: name === 'saturn' ? await addRings() : null,
          });
        });

      });
    });
  });
};

export const renderFlag = (context, center) => {
  const { scene } = context;

  const cylinderGeometry = new THREE.BoxGeometry(0.01, 0.01, 0.2);
  cylinderGeometry.translate(0, 0, -0.1);

  const material = new THREE.MeshBasicMaterial({ side: THREE.DoubleSide, color: new THREE.Color('white') });
  const cylinder = new THREE.Mesh(cylinderGeometry, material);

  cylinder.position.copy(center);
  cylinder.lookAt(0, 0, 0);
  scene.add(cylinder);
  return cylinder;
};

export const renderClient = ({ scene, renderer }, { position, areas, uv, planetGroup }, client) => {
  const texture = new THREE.TextureLoader().load(getLogoUrl(client.planet, client.area + 1, client.logo), (texture) => {
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    texture.anisotropy = renderer.capabilities.getMaxAnisotropy();
    texture.colorSpace = THREE.SRGBColorSpace;
  });

  const material = new THREE.MeshBasicMaterial({ map: texture, color: new THREE.Color('white'), side: THREE.DoubleSide, opacity: 0.8, transparent: true });

  const area = areas[client.area];
  const allVertices = [];
  const uvIndex = [];

  const points = [];
  area.triangles.forEach((t) => {
    points.push(...t);
  });

  points.forEach((p) => {
    const vertex = new THREE.Vector3(position.getX(p), position.getY(p), position.getZ(p));
    allVertices.push(vertex);
    uvIndex.push(uv.getX(p), uv.getY(p));
  });

  const geometry = new THREE.BufferGeometry().setFromPoints(allVertices);
  const newUv = new Float32Array(uvIndex);
  geometry.setAttribute('uv', new THREE.Float32BufferAttribute(newUv, 2))
  geometry.attributes.uv.needsUpdate = true;
  geometry.computeVertexNormals();

  const logo = new THREE.Mesh(geometry, material);

  planetGroup.add(logo);
  
  return {
    client, logo
  };
};

export const renderHighlight = ({ scene }, { position, uv }, area) => {
  const group = new THREE.Group();
  const material = new THREE.MeshBasicMaterial({ side: THREE.DoubleSide, color: new THREE.Color('white'), transparent: true, opacity: 0.8 });

  area.area.forEach((a) => {
    const vertex1 = new THREE.Vector3(position.getX(a.area.a), position.getY(a.area.a), position.getZ(a.area.a));
    const vertex2 = new THREE.Vector3(position.getX(a.area.b), position.getY(a.area.b), position.getZ(a.area.b));
    const vertex3 = new THREE.Vector3(position.getX(a.area.c), position.getY(a.area.c), position.getZ(a.area.c));

    const vertices = [vertex1, vertex2, vertex3];

    const geometry = new THREE.BufferGeometry().setFromPoints(vertices);
    geometry.setAttribute('uv', uv.clone())
    geometry.attributes.uv.needsUpdate = true;
    geometry.computeVertexNormals();
    const triangle = new THREE.Mesh(geometry, material);

    group.add(triangle);
  });

  scene.add(group);

  return group;
};

export const renderWalls = (context, { position }, area) => {
  const { scene } = context;
  const distance = 0.05;
  const group = new THREE.Group();

  for (let line of area.perimeter) {
    const geometry = new THREE.BufferGeometry();
    const v1 = getVectorForPosition(position, line[0]);
    const v2 = getVectorForPosition(position, line[1]);

    const direction3 = v2.clone().normalize();
    const direction4 = v1.clone().normalize();
    const v3 = v2.clone().addScaledVector(direction3, distance);
    const v4 = v1.clone().addScaledVector(direction4, distance);

    const positions1 = [
      v1.x, v1.y, v1.z,
      v2.x, v2.y, v2.z,
      v3.x, v3.y, v3.z,
      v1.x, v1.y, v1.z,
      v3.x, v3.y, v3.z,
      v4.x, v4.y, v4.z,
    ];

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions1, 3));
    geometry.computeVertexNormals();

    const material = new THREE.MeshStandardMaterial({ color: '#F00', roughness: 0.2, metalness: 0.3 })


    const object = new THREE.Mesh(geometry, material);
    group.add(object);
  }
  scene.add(group);
  return group;
};

export const drawStars = ({ scene }) => {
  const stars = new Array(0);
  const center = new THREE.Vector3(0, 0, 0);
  for (let i = 0; i < 5000; i++) {
    const x = THREE.MathUtils.randFloatSpread(2000);
    const y = THREE.MathUtils.randFloatSpread(2000);
    const z = THREE.MathUtils.randFloatSpread(2000);
    const distanceToCenter = center.distanceTo(new THREE.Vector3(x, y, z));
    if (distanceToCenter > 200) {
      stars.push(x, y, z);
    }
  }
  const starsGeometry = new THREE.BufferGeometry();
  starsGeometry.setAttribute(
    "position", new THREE.Float32BufferAttribute(stars, 3)
  );
  const starsMaterial = new THREE.PointsMaterial({ color: 0x888888, size: 1, fog: false });
  const starField = new THREE.Points(starsGeometry, starsMaterial);
  scene.add(starField);
};

export const generateThumbnail = async (planetId, area) => {
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
  
  const renderer = new THREE.WebGLRenderer();
  renderer.setSize(1024, 1024);
  
  const controls = new CameraControls( camera, renderer.domElement );
  controls.autoRotateSpeed = 0.2
  controls.distance = 2
  controls.maxDistance = 4
  controls.minDistance = 2
  controls.smoothTime = 0.25

  const tempContext = { scene, camera, renderer };
  
  const { planet, uv, rings } = await createPlanet(tempContext, planetId);
  scene.add(planet)
  if (rings) {
    scene.add(rings);
  }
  const position = planet.geometry.getAttribute('position');
  
  renderHighlight(tempContext, { position, uv }, area)

  const center = area.center;
  controls.lookInDirectionOf(-center.x, -center.y, -center.z, false);

  drawStars(tempContext);

  controls.update();
  renderer.render(scene, camera);
  
  const fileContent = renderer.domElement.toDataURL('image/png');
  // console.log(dt.replace(/^data:image\/[^;]/, 'data:application/octet-stream'));

  return fileContent;
};
